<div class="container-fluid pt-3 mya-appointment-detail h-100">
    <div class="row h-100">
        <div class="col-12">
            <div class="row h-100">
                <div class="col-12 col-md-3 mt-1 d-flex flex-column">
                    <div class="card mb-3" *ngIf="mentee">
                        <div class="card-body">
                            <div class="card-title mb-0">
                                <h4 class="mb-0 d-inline">{{mentee.firstName}}
                                    {{mentee.lastName}}</h4>
                                <span class="ms-2 mb-1 badge fs-4"
                                    [ngClass]="(isDangerStatus || isWarningStatus) ? ( isDangerStatus ? 'bg-danger' : 'bg-warning') : 'bg-success'"
                                    style="font-size: 1rem;">{{accountStatusPill}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" *ngIf="account?.accountStatus === AccountStatus.ConsultDecline">
                        <div class="card-body">
                            <div class="card-title">
                                <h4 class="mb-0">Assigned Mentor</h4>
                            </div>
                            <div class="card bg-danger text-white mb-0">
                                <div class="card-body py-3">
                                    <div class="d-flex no-block align-items-center">
                                        <div class="ms-3">
                                            <h4 class="font-medium mb-0 text-white">Declined</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-3" *ngIf="account?.accountStatus !== AccountStatus.ConsultDecline && mentor">
                        <div class="card-body">
                            <div class="card-title">
                                <h4 class="mb-0">Assigned Mentor</h4>
                            </div>
                            <div class="card bg-info text-white mb-0 assigned-mentor-card">
                                <div class="card-body">
                                    <div class="d-flex no-block align-items-center">
                                        <div class="user-pic">
                                            <img src="./assets/images/users/default-profile-pic.png" alt="users"
                                                class="rounded-circle" width="40" />
                                        </div>
                                        <div class="ms-3">
                                            <h4 class="font-medium mb-0 text-white">
                                                {{mentor.firstName}}
                                                {{mentor.lastName}}</h4>
                                            <span class="badge bg-light text-dark">Mentor</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card h-100 bg-transparent">
                        <div class="card-body p-0">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="card-title mb-0 d-flex">
                                        <h4 class="mb-0">Mentee Contacts</h4>
                                        <button type="button"
                                            class="justify-content-center btn btn-rounded btn-success ms-auto"
                                            (click)="editUser(null)">
                                            <i class="fas fa-plus align-self-center me-2"></i>
                                            Add
                                        </button>
                                    </div>

                                    <div class="card mt-3 h-90">
                                        <div class="card-body scrollable-content p-0">

                                            <div class="card bg-primary text-white" *ngIf="mentee">
                                                <div class="card-body">
                                                    <i class="fas fa-edit fa-lg float-end cursor-pointer"
                                                        (click)="editUser(mentee)"></i>
                                                    <div class="d-flex no-block align-items-center">
                                                        <div class="user-pic">
                                                            <img src="./assets/images/users/default-profile-pic.png"
                                                                alt="users" class="rounded-circle" width="40" />
                                                        </div>
                                                        <div class="ms-3">
                                                            <h4 class="font-medium mb-0 text-white">
                                                                {{mentee.firstName}}
                                                                {{mentee.lastName}}</h4>
                                                            <span class="badge bg-light text-dark">Mentee</span>
                                                        </div>
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.emailAddress">
                                                        <i class="far fa-envelope border-end border-primary pe-1"></i>
                                                        {{mentee.emailAddress}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.phoneNumber">
                                                        <i class="fas fa-phone border-end border-primary pe-1"></i>
                                                        {{getMaskedPhoneNumber(mentee)}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2" *ngIf="mentee.timeZone">
                                                        <i class="fas fa-clock border-end border-primary pe-1"></i>
                                                        {{getTimeZoneById(this.timeZones, mentee.timeZone)}}
                                                    </div>
                                                    <div class="card-text mb-1 mt-2"
                                                        *ngIf="mentee.mailingAddress?.addressLine1 || mentee.mailingAddress?.addressLine2">
                                                        <i class="fas fa-home border-end border-primary pe-1"></i>
                                                        {{mentee.mailingAddress?.addressLine1}}
                                                        {{mentee.mailingAddress?.addressLine2}}
                                                    </div>
                                                </div>
                                            </div>


                                            <ng-container *ngFor="let accountAccess of account?.accountAccesses">


                                                <div class="card bg-success text-white"
                                                    *ngIf="accountAccess.externalUser && userTypes[accountAccess.externalUser.userTypeId]?.code && userTypes[accountAccess.externalUser.userTypeId]?.code !== 'MT'">
                                                    <div class="card-body">
                                                        <i class="fas fa-edit fa-lg float-end cursor-pointer"
                                                            (click)="editUser(accountAccess?.externalUser ?? null)"></i>
                                                        <div class="d-flex no-block align-items-center">
                                                            <div class="user-pic">
                                                                <img src="./assets/images/users/default-profile-pic.png"
                                                                    alt="users" class="rounded-circle" width="40" />
                                                            </div>
                                                            <div class="ms-3">
                                                                <h4 class="font-medium mb-0 text-white">
                                                                    {{accountAccess?.externalUser?.firstName}}
                                                                    {{accountAccess?.externalUser?.lastName}}</h4>
                                                                <span class="badge bg-light text-dark">
                                                                    {{getUserType(accountAccess?.externalUser ??
                                                                    null)}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.emailAddress">
                                                            <i
                                                                class="far fa-envelope border-end border-primary pe-1"></i>
                                                            {{accountAccess?.externalUser?.emailAddress}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.phoneNumber">
                                                            <i class="fas fa-phone border-end border-primary pe-1"></i>
                                                            {{getMaskedPhoneNumber(accountAccess?.externalUser ?? null)}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.timeZone">
                                                            <i class="fas fa-clock border-end border-primary pe-1"></i>
                                                            {{getTimeZoneById(this.timeZones,
                                                            accountAccess.externalUser.timeZone)}}
                                                        </div>
                                                        <div class="card-text mb-1 mt-2"
                                                            *ngIf="accountAccess?.externalUser?.mailingAddress?.addressLine1 || accountAccess?.externalUser?.mailingAddress?.addressLine2">
                                                            <i class="fas fa-home border-end border-primary pe-1"></i>
                                                            {{accountAccess?.externalUser?.mailingAddress?.addressLine1}}
                                                            {{accountAccess?.externalUser?.mailingAddress?.addressLine2}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </ng-container>



                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>

                </div>

                <div class="col-12 col-md-7 mt-1">
                    <ng-container>
                        <div class="collapse" id="collapseConsultationForm">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-title mb-4">
                                        <h4>Consultation Form</h4>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Program Preference</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let mentoringProgram of accountMentoringPrograms">
                                                    <span class="badge bg-primary m-1">
                                                        {{mentoringPrograms[mentoringProgram.smartCodeId]?.label}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Starting With</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let mentoringSystem of accountMentoringSystems">
                                                    <span class="badge bg-primary m-1">
                                                        {{mentoringSystems[mentoringSystem.smartCodeId]?.label}}
                                                    </span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <h5 class="card-title mb-0">Area of Concerns</h5>
                                            <div class="mb-3">
                                                <ng-container *ngFor="let concern of accountConcerns">
                                                    <span class="badge bg-primary m-1">
                                                        {{concerns[concern.smartCodeId]?.code === 'O' ?
                                                        concerns[concern.smartCodeId]?.description :
                                                        concerns[concern.smartCodeId]?.label}}</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                        <div class="col-12 truncated">
                                            <h5 class="card-title mb-0">Tell us a bit about your situation</h5>
                                            <p class="collapsible" [class.collapsed]="isClassActive"
                                                (click)="isClassActive = !isClassActive">{{account?.aboutYourself ??
                                                "-"}}</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ng-container>

                    <mya-notes [accountId]="appointment?.accountId ?? null" *ngIf="appointment?.accountId"></mya-notes>
                </div>

                <div class="col-12 col-md-2 mt-1">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title mb-0">
                                <h4 class="mb-0">Actions</h4>
                            </div>

                            <div class="row mt-4" [ngClass]="appointment !== null ? 'd-block' : 'd-none'">
                                <div class="text-center">
                                    <button *ngIf="menteeTimeZoneSet && menteeEmailSet;else elseBlock" type="button"
                                        class="btn waves-effect waves-light btn-primary w-100" (click)="assignMentor()">
                                        <i data-feather="alert-octagon" class="feather-sm fill-white me-2"></i>Assign
                                        Mentor</button>

                                    <ng-template #elseBlock>
                                        <div class="d-flex assign-mentor-container w-100" popoverTitle="Requirements"
                                            placement="bottom" triggers="mouseenter:mouseleave"
                                            [ngbPopover]="popContent">
                                            <ng-template #popContent>
                                                <ul>
                                                    <li *ngIf="!menteeEmailSet">Please set mentee's email to enable this
                                                        feature.</li>
                                                    <li *ngIf="!menteeTimeZoneSet">Please set mentee's time zone to
                                                        enable this feature.</li>
                                                </ul>
                                            </ng-template>
                                            <button type="button" disabled
                                                class="btn waves-effect waves-light btn-primary w-100">
                                                <i class="fas fa-exclamation-circle fill-white me-2"></i>Assign
                                                Mentor</button>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button"
                                        [disabled]="account?.accountStatus === AccountStatus.ConsultDecline"
                                        class="btn waves-effect waves-light btn-danger w-100"
                                        (click)="openDeclineMenteeModal()">
                                        Decline Mentee</button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" (click)="showConsultationForm = !showConsultationForm"
                                        class="btn waves-effect waves-light btn-primary w-100" data-bs-toggle="collapse"
                                        data-bs-target="#collapseConsultationForm">
                                        {{showConsultationForm ? 'Hide' : 'View'}} Consultation Form</button>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="text-center">
                                    <button type="button" class="btn waves-effect waves-light btn-primary w-100"
                                        (click)="navigateToView(AppointmentDetailViews.ViewAppointments)">
                                        View Upcoming Sessions
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


        </div>
    </div>
</div>

<mya-mentor-selection-modal (mentorSelected)="onMentorSelected($event)"></mya-mentor-selection-modal>
<mya-decline-mentee-modal (menteeDecline)="onMenteeDecline($event)"></mya-decline-mentee-modal>
<mya-external-user-modal (addUser)="onAddUser($event)" (updateUser)="onUpdateUser($event)"></mya-external-user-modal>