<div class="container-fluid pt-3 mya-appointments">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="d-flex border-bottom title-part-padding align-items-center">
          <div>
            <h4 class="card-title mb-0">Appointments</h4>
          </div>
        </div>

        <dx-data-grid class="m-2" [dataSource]="dataSource" [showBorders]="true"
          [rowAlternationEnabled]="true" [hoverStateEnabled]="true" (onSelectionChanged)="rowSelected($event)">

          <dxi-column caption="Date" [calculateCellValue]="fetchAppointmentDate" dataType="string"> </dxi-column>
          <dxi-column caption="Time" [calculateCellValue]="fetchAppointmentTime" dataType="string"> </dxi-column>
          <dxi-column dataField="parentFullName" caption="Parent" dataType="string"> </dxi-column>
          <dxi-column dataField="parentPhoneNumber" caption="Parent Phone #" dataType="string"> </dxi-column>
          <dxi-column dataField="menteeFullName" caption="Mentee" dataType="string"> </dxi-column>
          <dxi-column dataField="parentEmail" caption="Parent Email" dataType="string"> </dxi-column>
          <dxi-column dataField="menteeEmail" caption="Mentee Email" dataType="string"> </dxi-column>
          <dxi-column dataField="durationInMinutes" caption="Duration (Minutes)" dataType="string"> </dxi-column>
          <dxi-column dataField="accountStatus" caption="Account Status" dataType="string"> </dxi-column>
          <dxi-column type="buttons">
            <dxi-button
            cssClass="btn btn-info text-white text-decoration-none py-0"
            text="Join"
            hint="Join Meeting"
            [onClick]="joinMeeting">
        </dxi-button>
        </dxi-column>
          <dxo-selection mode="single"></dxo-selection>
          <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
          <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
          <dxo-paging [pageSize]="50"> </dxo-paging>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-group-panel [visible]="true"></dxo-group-panel>
          <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
        </dx-data-grid>
      </div>
    </div>
  </div>
</div>