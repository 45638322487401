<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <form [formGroup]="notesForm" (ngSubmit)="onSubmit()">
                <div class="modal-header modal-colored-header bg-danger text-white">
                    <h4 class="modal-title">
                        Confirm Decline Mentee
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <label for="notes-text" class="control-label">Please Enter the Reason you are Declining this
                            Mentee (Required):</label>
                        <textarea class="form-control" id="notes-text" formControlName="notes"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button class="btn btn-light-danger text-danger font-medium" [disabled]="notesForm.invalid">
                        Save changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>