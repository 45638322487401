<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Reschedule Appointment
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div class="modal-body pt-0 p-4">
                <h5 class="mt-0">Please select date and time</h5>
                <div class="row pt-3">
                    <div class="col-12">
                        <mya-appointment-datepicker class="dark-mode" *ngIf="bookingStaffId && serviceId && currentDate"
                            [currentDate]="currentDate" [center]="true" [serviceId]="serviceId"
                            [bookingStaffId]="bookingStaffId"
                            (appointmentSlotSelected)="appointmentSlotSelected($event)">
                        </mya-appointment-datepicker>
                    </div>
                </div>
            </div>

            <div class="modal-footer pt-1 p-4">
                <button type="button" class="btn btn-light-primary text-primary font-medium"
                    [disabled]="appointmentSlot === null" (click)="rescheduleAppointment()">
                    Submit
                </button>
            </div>
        </div>
    </div>
</div>