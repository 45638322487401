import { Inject, Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { AddSupportUserToAccountRequest, AddSupportUserToAccountResult, EditExternalUserRequest, EditExternalUserResult } from '@mya/models';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { APP_CONFIG } from "@mya/configuration";
import { LoaderService } from './loader.service';
declare const toastr: any;

@Injectable({
    providedIn: 'root'
})
export class ExternalUserService {
    baseApiUrl = '';

    constructor(private http: HttpClient, private loaderService: LoaderService,
        @Inject(APP_CONFIG) appConfig: any) {
        this.baseApiUrl = `${appConfig.apiUrls.externalUser}api/externalUser`
    }

    addSupportUserToAccount(request: AddSupportUserToAccountRequest, accountId: string, loaderIdentifier: string): Observable<AddSupportUserToAccountResult> {
        this.loaderService.show(loaderIdentifier);
        return this.http.post<AddSupportUserToAccountResult>(`${this.baseApiUrl}/add-support-user-to-account/${accountId}`, request)
            .pipe(catchError((error: HttpErrorResponse) => {
                this.loaderService.hide(loaderIdentifier);
                if (error.status == HttpStatusCode.Conflict) {
                    toastr.error(error.error);
                }
                throw error;
            }));
    }

    editExternalUser(request: EditExternalUserRequest, loaderIdentifier: string): Observable<EditExternalUserResult> {
        this.loaderService.show(loaderIdentifier);
        return this.http.post<EditExternalUserResult>(`${this.baseApiUrl}/edit-external-user`, request)
            .pipe(catchError((error: HttpErrorResponse) => {
                this.loaderService.hide(loaderIdentifier);
                if (error.status == HttpStatusCode.Conflict) {
                    toastr.error(error.error);
                }
                throw error;
            }));
    }
}
