import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, catchError } from 'rxjs';
import { JwtService } from '../../services/jwt.service';
import { AuthenticationService } from '../../services/authentication.service';
import { InternalUserClaimTypes, InternalUserRoles } from '@mya/models';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
    private jwtService: JwtService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean>(observer => {
      this.authenticationService.getToken().pipe(catchError((error) => {
        this.router.navigate(['/forbidden']);
        throw error;
      })).subscribe(token => {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        const roles = decodedToken[InternalUserClaimTypes.Roles];
        if ((Array.isArray(roles) && roles.some(i => i === InternalUserRoles.Consultant)) || roles === InternalUserRoles.Consultant) {
          observer.next(true);
        } else {
          this.router.navigate(['/forbidden']);
        }
      })
    });
  }

}
