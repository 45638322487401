<!-- -------------------------------------------------------------- -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- -------------------------------------------------------------- -->
<div id="main-wrapper" class="h-100">
  <!-- -------------------------------------------------------------- -->
  <!-- Topbar header - style you can find in pages.scss -->
  <!-- -------------------------------------------------------------- -->
  <header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
      <div class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
          <i class="ri-close-line fs-6 ri-menu-2-line"></i>
        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- Logo -->
        <!-- -------------------------------------------------------------- -->
        <a class="navbar-brand" href="index.html">
          <!-- Logo text -->
          <span class="logo-icon pt-3">
            <img src="./assets/images/mya-logo.png" class="light-logo" alt="homepage" />
          </span>
        </a>
        <!-- -------------------------------------------------------------- -->
        <!-- End Logo -->
        <!-- -------------------------------------------------------------- -->
        <!-- -------------------------------------------------------------- -->
        <!-- Toggle which is visible on mobile only -->
        <!-- -------------------------------------------------------------- -->
        <a class="topbartoggler d-block d-md-none waves-effect waves-light" href="javascript:void(0)"
          data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation"><i class="ri-more-line fs-6"></i></a>
      </div>
      <!-- -------------------------------------------------------------- -->
      <!-- End Logo -->
      <!-- -------------------------------------------------------------- -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- -------------------------------------------------------------- -->
        <!-- toggle and nav items -->
        <!-- -------------------------------------------------------------- -->
        <ul class="navbar-nav me-auto">
          <li class="nav-item ms-5">
            <a class="nav-link dropdown-toggle waves-effect waves-dark" role="button" routerLink="/home"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-none d-md-block fs-6 fw-bolder">Consultant Portal
              </span>
            </a>
          </li>
        </ul>

        <ul class="navbar-nav" *ngIf="isDateOverridden">
          <li class="nav-item ms-5 align-content-center">
            <span class="d-none d-md-block fs-4 text-white">{{this.currentDate | date: 'medium'}}
            </span>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!-- -------------------------------------------------------------- -->
  <!-- End Topbar header -->
  <!-- -------------------------------------------------------------- -->
  <!-- -------------------------------------------------------------- -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- -------------------------------------------------------------- -->
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" *ngIf="loginDisplay">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <!-- User Profile-->
          <li>
            <!-- User Profile-->
            <div class="user-profile d-flex no-block dropdown mt-3">
              <div class="user-pic">
                <img src="./assets/images/users/default-profile-pic.png" alt="users" class="rounded-circle"
                  width="40" />
              </div>
              <div class="user-content hide-menu ms-2">
                <a href="#" class="" id="Userdd" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  <h5 class="mb-0 user-name font-medium">
                    {{name ? name : 'User'}}
                    <!-- <i data-feather="chevron-down" class="feather-sm"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-chevron-down feather-sm" _ngcontent-qil-c19="">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </h5>
                  <span class="op-5 user-email">{{email ?? ''}}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="Userdd">
                  <a class="dropdown-item cursor-pointer" (click)="logout()">
                    <!-- <i data-feather="log-out" class="feather-sm text-danger me-1"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-log-out feather-sm text-danger me-1" _ngcontent-qil-c19="">
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    Logout
                  </a>
                </div>
              </div>
            </div>
            <!-- End User Profile-->
          </li>
          <!-- <li class="nav-small-cap">
            <i class="mdi mdi-dots-horizontal"></i>
            <span class="hide-menu">Consultant</span>
          </li> -->
          <!-- <li class="sidebar-item">
                        <a class="sidebar-link waves-effect waves-dark sidebar-link" href="app-chats.html" routerLink="/home"
                            aria-expanded="false"><i class="mdi mdi-adjust"></i><span class="hide-menu">Home</span></a>
                    </li> -->
          <li class="sidebar-item" [ngClass]="{ 'selected': url === '/dashboard'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link" routerLink="/dashboard"
              aria-expanded="false"><i class="mdi mdi-adjust"></i><span class="hide-menu">Dashboard</span></a>
          </li>
          <li class="sidebar-item" [ngClass]="{ 'selected': url === '/appointments'}">
            <a class="sidebar-link waves-effect waves-dark sidebar-link" routerLink="/appointments"
              aria-expanded="false"><i class="mdi mdi-adjust"></i><span class="hide-menu">Appointments</span></a>
          </li>
          <!-- <li class="sidebar-item">
            <a class="sidebar-link waves-effect waves-dark sidebar-link" href="app-chats.html" routerLink="/consultants"
              aria-expanded="false"><i class="mdi mdi-adjust"></i><span class="hide-menu">Consultants</span></a>
          </li> -->
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
  <!-- -------------------------------------------------------------- -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- -------------------------------------------------------------- -->
  <!-- -------------------------------------------------------------- -->
  <!-- Page wrapper  -->
  <!-- -------------------------------------------------------------- -->
  <div class="page-wrapper h-100 d-block overflow-y-auto">
    <router-outlet></router-outlet>
  </div>
  <!-- -------------------------------------------------------------- -->
  <!-- End Page wrapper  -->
  <!-- -------------------------------------------------------------- -->
</div>
<!-- -------------------------------------------------------------- -->
<!-- End Wrapper -->
<!-- -------------------------------------------------------------- -->