import { Component, Input } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';

@Component({
  selector: 'mya-join-appointment-modal',
  templateUrl: './join-appointment-modal.component.html',
  styleUrls: ['./join-appointment-modal.component.scss'],
})
export class JoinAppointmentModalComponent {
  @Input() meetingUrl: string | null = null;
  elementId = ModalConstant.JOIN_APPOINTMENT_MODAL;

  openJoinAppointmentModal() {
    if (this.meetingUrl)
      window.open(this.meetingUrl, '_blank');
  }
}
