import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { GetSmartTypeByNameResult, SmartCode, SmartTypes } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartTypesService implements OnDestroy {
  private baseApiUrl = '';
  subscriptions: Subs[] = [];
  private _smartTypes: Record<string, BehaviorSubject<SmartCode[]>> = {};

  constructor(private http: HttpClient, @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = appConfig.apiUrls.smartType + 'api/smartType';
  }

  public SmartTypes(smartType: SmartTypes): Observable<SmartCode[]> {
    this.EnsureSmartType(smartType);

    if (!this._smartTypes[smartType])
      this._smartTypes[smartType] = new BehaviorSubject<SmartCode[]>([]);

    return this._smartTypes[smartType];
  }

  private EnsureSmartType(smartType: SmartTypes) {
    if (this._smartTypes[smartType])
      return;

    this.subscriptions.push(this.GetSmartType(smartType).subscribe(smartTypeDto => {
      if (smartTypeDto == null || smartTypeDto.smartCodes == undefined)
        return;

      this._smartTypes[smartType].next(smartTypeDto.smartCodes);
    }));
  }

  private GetSmartType(smartType: SmartTypes): Observable<GetSmartTypeByNameResult> {
    return this.http.get<GetSmartTypeByNameResult>(`${this.baseApiUrl}/${smartType}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
