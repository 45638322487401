import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { Appointment, CancelAppointmentRequest, GetAllAppointmentsResult, GetAppointmentByIdResult, RescheduleAppointmentRequest } from '@mya/models';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { APP_CONFIG } from "@mya/configuration";
import { LoaderService } from './loader.service';
import { v4 as uuid } from 'uuid';
import { Subscription as Subs } from 'rxjs';
import { DatePipe } from '@angular/common';
declare const toastr: any;

@Injectable({
  providedIn: 'root'
})
export class AppointmentService implements OnDestroy {
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any, private datePipe: DatePipe) {
    this.baseApiUrl = `${appConfig.apiUrls.appointment}api/appointment`
  }

  private readonly appointment = new BehaviorSubject<Appointment | null>(null);
  public readonly Appointment$: Observable<Appointment | null> = this.appointment;

  private readonly appointments = new BehaviorSubject<Appointment[]>([]);
  public readonly Appointments$: Observable<Appointment[]> = this.appointments;

  private readonly userAppointments = new BehaviorSubject<Appointment[]>([]);
  public readonly UserAppointments$: Observable<Appointment[]> = this.userAppointments;
  
  getConsultationAppointments(params: HttpParams) {
    const loaderIdentifier = uuid();
    this.loaderService.show(loaderIdentifier);
    return this.http.get(`${this.baseApiUrl}/get-consultation-appointments`, { params })
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }), map((result) => {
        this.loaderService.hide(loaderIdentifier);
        return result;
      }));
  }

  getAppointment(appointmentId: string, refresh: boolean = false) {
    this.appointment.next(null);
    const selectedAppointment = this.appointments.value.find(i => i.id == appointmentId);
    if (!refresh && selectedAppointment) {
      this.appointment.next(selectedAppointment);
    } else {
      const loaderIdentifier = uuid();
      this.loaderService.show(loaderIdentifier);
      this.subscriptions.push(this.http.get<GetAppointmentByIdResult>(`${this.baseApiUrl}/${appointmentId}`)
        .pipe(catchError((error: HttpErrorResponse) => {
          this.loaderService.hide(loaderIdentifier);
          throw error;
        }))
        .subscribe(data => {
          this.loaderService.hide(loaderIdentifier);
          if (data.appointment) {
            const index: number = this.appointments.value.findIndex(i => i.id == appointmentId);
            if (index !== -1) {
              this.appointments.value.splice(index, 1);
            }

            this.appointments.value.push(data.appointment);
            this.appointment.next(data.appointment);
          }
        }));
    }
  }

  getAppointmentsByUser(startDate: Date, endDate: Date): void {
    const loaderIdentifier = uuid();
    const formattedStartDate = this.datePipe.transform(new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss');
    const formatterdEndDate = this.datePipe.transform(new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000), 'YYYY-MM-ddTHH:mm:ss');
    this.loaderService.show(loaderIdentifier);
    this.subscriptions.push(this.http.get<GetAllAppointmentsResult>(`${this.baseApiUrl}/get-all-appointments/${formattedStartDate}/${formatterdEndDate}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }))
      .subscribe(data => {
        this.userAppointments.next(data.appointments);
        this.loaderService.hide(loaderIdentifier);
      }));
  }

  cancelAppointment(request: CancelAppointmentRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/cancel-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  rescheduleAppointment(request: RescheduleAppointmentRequest, loaderIdentifier: string): Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(`${this.baseApiUrl}/reschedule-appointment`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        toastr.error('An error has occured');
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
