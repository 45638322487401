import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentDetailViews } from '../../shared/enums/appointment-detail-views.enum';
import { Subscription as Subs } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { style, transition, animate, trigger } from '@angular/animations';

@Component({
  selector: 'mya-appointment-detail-base',
  templateUrl: './appointment-detail-base.component.html',
  styleUrls: ['./appointment-detail-base.component.scss'],
  animations: [
    trigger('disableInitalTrigger', [
      transition(':enter', [])
    ]),
    trigger("mainTrigger", [
      transition(":enter", [style({ transform: 'translateX(100%)' }), animate('500ms', style({ transform: 'translateX(0)' }))]),
      transition(":leave", [style({ transform: 'translateX(0)' }), animate('500ms', style({ transform: 'translateX(100%)' }))])
    ]),
    trigger("childTrigger", [
      transition(":enter", [style({ transform: 'translateX(-100%)' }), animate('500ms', style({ transform: 'translateX(0)' }))]),
      transition(":leave", [style({ transform: 'translateX(0)' }), animate('500ms', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class AppointmentDetailBaseComponent implements OnDestroy {
  subscriptions: Subs[] = [];
  currentView: AppointmentDetailViews | null = AppointmentDetailViews.Main;
  appointmentId: string | null = null;
  tenant: string | null = null;

  get AppointmentDetailViews() {
    return AppointmentDetailViews;
  }

  constructor(private route: ActivatedRoute,) {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      this.appointmentId = params.get('id');
      this.tenant = params.get('tenant');
    }));
  }

  currentViewedChanged(view: AppointmentDetailViews) {
    this.currentView = view;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
