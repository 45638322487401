<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title">
                    Appointment Cancellation
                </h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-0 p-4">
                <h5 class="mt-0">Please Confirm if you want to cancel the appointment?</h5>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn waves-effect waves-light btn-outline-primary btn-cancel"
                    data-bs-dismiss="modal">
                    Close
                </button>
                <button type="button" class="btn waves-effect waves-light btn-danger btn-confirm"
                    (click)="cancelAppointment()">Confirm Cancellation</button>
            </div>
        </div>
    </div>
</div>