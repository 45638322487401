import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject, Subscription as Subs, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'mya-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'consult';
  isIframe = false;
  loginDisplay = false;
  subscriptions: Subs[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(private broadcastService: MsalBroadcastService, private msalService: MsalService) { }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.subscriptions.push(this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      }));
  }

  
  ngAfterViewInit(): void {
    setTimeout(() => {
      document.body.insertAdjacentHTML("beforeend", `<style>:root {--widget-button-bg: #2962ff !important;--widget-button-text: #FFFFFF !important;}</style>`);
    }, 1000);
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}