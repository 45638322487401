<div class="container-fluid h-100 pt-3">
    <div class="card mb-0">
        <div class="row">
            <div class="col-12">

                <div class="card-body h-100 p-4">
                    <div class="col-md-12 d-flex flex-row-reverse mb-3 justify-content-between">
                        <button type="button" class="btn waves-effect waves-light btn-primary px-5" (click)="back()">
                            Back
                        </button>
                        <div class="text-white align-content-end">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input current" type="checkbox" id="current-check" value="true"
                                    [(ngModel)]="currentAppointments" (change)="currentAppointmentsChecked()">
                                <label class="form-check-label" for="current-check">{{mentee?.firstName}}'s
                                    Appointments</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input other" type="checkbox" id="other-check" value="true"
                                    [(ngModel)]="otherAppointments" (change)="otherAppointmentsChecked()">
                                <label class="form-check-label" for="other-check">Other Mentees Appointments</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input outlook" type="checkbox" id="outlook-check" value="true"
                                    [(ngModel)]="outlookEvents" (change)="outlookEventsChecked()">
                                <label class="form-check-label" for="outlook-check">Outlook Events</label>
                            </div>
                        </div>
                    </div>
                    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                </div>
            </div>
        </div>
    </div>

</div>

<mya-reschedule-appointment-modal [selectedAppointmentId]="selectedAppointmentId" [serviceId]="serviceId"
    [bookingStaffId]="consultant?.bookingStaffId ?? null"></mya-reschedule-appointment-modal>

<mya-cancel-appointment-modal [selectedAppointmentId]="selectedAppointmentId"></mya-cancel-appointment-modal>

<mya-edit-appointment-modal [selectedBookingAppointmentId]="selectedBookingAppointmentId" [meetingUrl]="selectedMeetingUrl"></mya-edit-appointment-modal>