import { Component, Input, OnDestroy } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';
import { CancelAppointmentRequest } from '@mya/models';
import { AppointmentService } from '../../services/appointment.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-cancel-appointment-modal',
  templateUrl: './cancel-appointment-modal.component.html',
  styleUrls: ['./cancel-appointment-modal.component.scss'],
})
export class CancelAppointmentModalComponent implements OnDestroy {
  @Input() selectedAppointmentId: string | null = null;
  subscriptions: Subs[] = [];
  elementId = ModalConstant.CANCEL_APPOINTMENT_MODAL;

  constructor(private appointmentService: AppointmentService,
    private loaderService: LoaderService,
    private modalService: ModalService) { }

  cancelAppointment() {
    if (this.selectedAppointmentId) {

      const request: CancelAppointmentRequest = {
        appointmentId: this.selectedAppointmentId,
        isRefundable: false,
        cancellationReason: 'Canceled by Mentor',
        appointmentCancelReasons: []
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.cancelAppointment(request, loaderIdentifier).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        toastr.success("Appointment cancelled");
        this.modalService.hide(ModalConstant.CANCEL_APPOINTMENT_MODAL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
