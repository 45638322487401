import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { InternalUser } from '@mya/models';
import { MentorService } from '../../services/mentor.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { ModalService } from '../../services/modal.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-mentor-selection-modal',
  templateUrl: './mentor-selection-modal.component.html',
  styleUrls: ['./mentor-selection-modal.component.scss'],
})
export class MentorSelectionModalComponent implements OnInit, OnDestroy {
  @Output() mentorSelected = new EventEmitter<InternalUser>();
  subscriptions: Subs[] = [];
  elementId = ModalConstant.MENTOR_SELECTION_MODAL;
  mentors: InternalUser[] = [];
  menteeCount: Record<string, number> = {};

  mentorsForm = this.formBuilder.group({
    mentor: [null as string | null, [Validators.required]]
  });

  constructor(private mentorService: MentorService,
    private modalService: ModalService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loadMentors();
  }

  public loadMentors() {
    this.subscriptions.push(this.mentorService.Mentors$.subscribe(i => this.mentors = i));
    this.subscriptions.push(this.mentorService.MenteeCount$.subscribe(i => this.menteeCount = i));
    this.mentorService.getAllMentors();
    this.modalService.show(ModalConstant.MENTOR_SELECTION_MODAL);
  }


  assignMentor(mentor: InternalUser) {
    if (mentor.id) {
      this.mentorsForm.controls.mentor.setValue(mentor.id);
    }
  }

  onSubmit() {
    if (this.mentorsForm.controls.mentor.value) {
      this.mentorSelected.emit(this.mentors.find(mentor => mentor.id == this.mentorsForm.controls.mentor.value));
      this.mentorsForm.controls.mentor.setValue('');
      this.modalService.hide(ModalConstant.MENTOR_SELECTION_MODAL);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

