<div [id]="elementId" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body p-4">
                <div class="row">
                    <div class="col-12 text-center">
                        <button type="button" class="btn waves-effect waves-light btn-lg btn-secondary w-100"
                            (click)="openJoinAppointmentModal()">Join</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>