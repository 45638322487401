<div class="container-fluid pt-3">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="d-flex border-bottom title-part-padding align-items-center">
                    <div>
                        <h4 class="card-title mb-0">Dashboard</h4>
                    </div>
                </div>

                <div class="card-body h-100 p-4">
                    <div class="col-md-12 d-flex flex-row mb-3 justify-content-between">
                        <div class="text-white align-content-end">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input current" type="checkbox" id="current-check" value="true"
                                    [(ngModel)]="currentAppointments" (change)="currentAppointmentsChecked()">
                                <label class="form-check-label" for="current-check">My Appointments</label>
                            </div>
                            <div class="form-check form-check-inline" *ngIf="isSuperAdmin">
                                <input class="form-check-input other" type="checkbox" id="other-check" value="true"
                                    [(ngModel)]="otherAppointments" (change)="otherAppointmentsChecked()">
                                <label class="form-check-label" for="other-check">Other Mentor Appointments</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input outlook" type="checkbox" id="outlook-check" value="true"
                                    [(ngModel)]="outlookEvents" (change)="outlookEventsChecked()">
                                <label class="form-check-label" for="outlook-check">Outlook Events</label>
                            </div>
                        </div>
                    </div>
                    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
                </div>

            </div>
        </div>
    </div>
</div>