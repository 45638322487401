<div *ngIf="!loginDisplay" class="mya-home">
  <div class="overflow-auto">
    <div class="p-4 mx-auto mt-5 home-container">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title text-center">Welcome to the Consultant Portal.</h3>
          <a (click)="login()" href="javascript:void(0)" class="btn btn-lg btn-info waves-effect waves-light m-3">Click
            to Login</a>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>