import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, first, map, mergeMap, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { APP_CONFIG } from '@mya/configuration';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  baseApiUrl = '';

  constructor(private authenticationService: AuthenticationService, @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.authentication}auth`

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(this.baseApiUrl)) {
      return this.authenticationService.getToken().pipe(
        first(),
        mergeMap(token => {
          if (token) {
            const tokenizedReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
            return this.createHandle(tokenizedReq, next);
          }
          return this.createHandle(req, next);
        })
      );
    } else {
      return this.createHandle(req, next);
    }
  }

  createHandle(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && !req.url.startsWith(this.baseApiUrl) &&
        event.status == HttpStatusCode.Unauthorized) {

        this.authenticationService.refreshToken();

      }
      return event;
    }), catchError((error: HttpErrorResponse) => {
      if (error.status == HttpStatusCode.Unauthorized && !req.url.startsWith(this.baseApiUrl)) {
        this.authenticationService.refreshToken();
      }
      throw error;
    }));
  }
}