<div class="mya-note-card" [ngClass]="noteTypeClass" *ngIf="historyItem">
    <div class="card card-body mb-2">
        <span class="side-stick"></span>
        <h5 class="note-title text-truncate w-75 mb-0">
            {{historyItem.historyTypeId ? HistoryTypeLookup[historyItem.historyTypeId] : ''}}
            <i class="point ri-checkbox-blank-circle-fill ms-1 fs-1"></i>
        </h5>
        <p class="note-date fs-2 text-muted">
            {{getDateWithOffset(historyItem.creationDate) | date: 'mediumDate'}} @
            {{getDateWithOffset(historyItem.creationDate) | date: 'shortTime'}}
        </p>
        <div class="note-content">
            <angular-editor class="angular-editor-readonly angular-editor-single-line" [(ngModel)]="historyItem.note"
                [config]="editorConfig"></angular-editor>

            <button type="button" class="btn waves-effect waves-light btn-rounded btn-secondary btn-view-details"
                (click)="viewNoteDetails()">Read More</button>

            <ng-container *ngIf="historyItem.historyAttachments.length > 0">

                <h5 class="mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-paperclip feather-sm">
                        <path
                            d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48">
                        </path>
                    </svg>
                    Attachments <span>({{historyItem.historyAttachments.length}})</span>
                </h5>

                <div class="row m-0 mt-3">
                    <div class="col-md-6" *ngFor="let historyAttachment of historyItem.historyAttachments">
                        <div class="card rounded-3 card-hover mb-2 file-card bg-secondary"
                            (click)="downloadAttachment(historyAttachment)">
                            <div class="card-body p-3 cursor-pointer">

                                <div class="d-flex align-items-center">
                                    <span class="flex-shrink-0">
                                        <i class="ri-file-text-fill text-primary file-icon"></i>
                                    </span>
                                    <div class="ms-4 flex-grow-1 w-50">
                                        <div>
                                            <h4 class="card-title text-dark mb-0 single-line">
                                                {{historyAttachment.filename}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</div>