import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserUtils } from '@azure/msal-browser';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { LayoutModule } from './layout/layout.module';
import {
  MsalModule,
  MsalRedirectComponent,
  MsalGuard,
  MsalBroadcastService,
} from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { InterceptorService } from './services/interceptor.service';
import { environment as env } from '../environments/environment';
import { APP_CONFIG } from '@mya/configuration';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { RoleGuard } from './guards/role/role.guard';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    LayoutModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: environment.auth,
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: [`api://${environment.auth.clientId}/Scope`],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.graphAPi, [`api://${environment.auth.clientId}/Scope`]],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: APP_CONFIG,
      useValue: env,
    },
    { provide: APP_BASE_HREF, useValue: '/consult' },
    MsalGuard,
    RoleGuard,
    MsalBroadcastService,
    DatePipe,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
