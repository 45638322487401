import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Account, AddAccountHistoryRequest, HistoryAttachment, HistoryItem, HistoryTypeEnum, HistoryTypeLookup, InternalUser, removeFile, selectFile } from '@mya/models';
import { defaultEditorConfig } from '../../common/configs/angular-editor-configs';
import { v4 as uuid } from 'uuid';
import { AccountService } from '../../services/account.service';
import { HistoryService } from '../../services/history.service';
import { LoaderService } from '../../services/loader.service';
import { InternalUserService } from '../../services/internal-user.service';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-notes-editor',
  templateUrl: './notes-editor.component.html',
  styleUrls: ['./notes-editor.component.scss'],
})
export class NotesEditorComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInputRef: ElementRef | null = null;
  @Input() accountId: string | null = null;
  overriddenDate: Date | null = null;
  account: Account | null = null;
  consultant: InternalUser | null = null;
  editorConfig = defaultEditorConfig;
  selectedFiles: File[] = [];
  subscriptions: Subs[] = [];
  handleFileInput = selectFile;
  removeFile = removeFile;

  historyTypes = Object.values(HistoryTypeEnum).filter(value => typeof value === 'number') as HistoryTypeEnum[];
  notesForm = this.formBuilder.group({
    notes: [null as null | string, [Validators.required]]
  });

  get HistoryTypeLookup() {
    return HistoryTypeLookup;
  }

  get HistoryTypeEnum() {
    return HistoryTypeEnum;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private formBuilder: FormBuilder,
    private historyService: HistoryService,
    private accountService: AccountService,
    private loaderService: LoaderService,
    private internalUserService: InternalUserService,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Consultant$.subscribe(user => {
      this.consultant = user;
    }));

    if (this.accountId) {
      this.subscriptions.push(this.accountService.getAccount(this.accountId).subscribe(account => {
        if (account) {
          this.account = account;
        }
      }));
    }
  }

  saveNotes() {
    if (this.account?.id == null || this.notesForm.controls.notes.value == null) return;

    const historyItemId = uuid();
    const historyAttachments: HistoryAttachment[] = [];

    if (this.selectedFiles.length == 0) {
      const request: AddAccountHistoryRequest = {
        historyItem: <HistoryItem>{
          id: historyItemId,
          note: this.notesForm.controls.notes.value,
          historyTypeId: HistoryTypeEnum.Consultant,
          creationDate: this.currentDate,
          accountId: this.account?.id,
          createdById: this.consultant?.id ?? null,
          isConfidential: false
        },
        historyAccess: []
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.historyService.addNotes(request, loaderIdentifier)
        .subscribe(() => {
          this.loaderService.hide(loaderIdentifier);
          this.selectedFiles = [];
          if (this.fileInputRef)
            this.fileInputRef.nativeElement.value = null;
          this.notesForm.controls.notes.reset();
          if (this.accountId) {
            this.historyService.getHistoryItemsByAccount(this.accountId);
          }
        }));

    } else {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const fileContent = fileReader.result as string;
          const base64String = fileContent.split(',')[1];

          historyAttachments.push(<HistoryAttachment>{
            id: uuid(),
            content: base64String,
            filename: file.name,
            historyItemId: historyItemId
          });

          if (i === this.selectedFiles.length - 1) {
            const request: AddAccountHistoryRequest = {
              historyItem: <HistoryItem>{
                id: historyItemId,
                note: this.notesForm.controls.notes.value,
                historyTypeId: HistoryTypeEnum.Consultant,
                creationDate: this.currentDate,
                accountId: this.account?.id,
                createdById: this.consultant?.id ?? null,
                historyAttachments: historyAttachments,
                isConfidential: false
              },
              historyAccess: []
            };

            const loaderIdentifier = uuid();
            this.subscriptions.push(this.historyService.addNotes(request, loaderIdentifier)
              .subscribe(() => {
                this.loaderService.hide(loaderIdentifier);
                this.selectedFiles = [];
                if (this.fileInputRef)
                  this.fileInputRef.nativeElement.value = null;
                this.notesForm.controls.notes.reset();
                if (this.accountId) {
                  this.historyService.getHistoryItemsByAccount(this.accountId);
                }
              }));
          }
        };
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
