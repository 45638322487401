import { AfterContentChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import {
  AccountStatus,
  AddSupportUserToAccountRequest,
  AssignMentorToAccountRequest,
  DeclineAccountRequest,
  EditExternalUserRequest,
  Account,
  Appointment,
  Concerns,
  ExternalUser,
  InternalUser,
  SmartCode,
  SmartTypes,
  MentoringProgram,
  MentoringSystem,
  AccountStatusLookup,
  getMentee,
  getTimeZoneById,
  ReferenceItem,
  ReferenceDataTypes
} from '@mya/models';
import { ExternalUserModalComponent } from '../../modals/external-user-modal/external-user-modal.component';
import { MentorSelectionModalComponent } from '../../modals/mentor-selection-modal/mentor-selection-modal.component';
import { AccountService } from '../../services/account.service';
import { AppointmentService } from '../../services/appointment.service';
import { ExternalUserService } from '../../services/external-user.service';
import { HistoryService } from '../../services/history.service';
import { LoaderService } from '../../services/loader.service';
import { MentorService } from '../../services/mentor.service';
import { SmartTypesService } from '../../services/smart-types.service';
import { ModalService } from '../../services/modal.service';
import { ModalConstant } from '../../common/constants/modal.constant';
import { v4 as uuid } from 'uuid';
import { ReferenceDataService } from '../../services/reference-data.service';
import { Subscription as Subs } from 'rxjs';
import { AppointmentDetailViews } from '../../shared/enums/appointment-detail-views.enum';

@Component({
  selector: 'mya-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss'],
})
export class AppointmentDetailComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild(MentorSelectionModalComponent)
  MentorSelectionModalComponent!: MentorSelectionModalComponent;
  @ViewChild(ExternalUserModalComponent)
  ExternalUserModalComponent!: ExternalUserModalComponent;
  @Output() changeCurrentView = new EventEmitter<AppointmentDetailViews>();
  @Input() appointmentId: string | null = null;
  @Input() tenant: string | null = null;

  showConsultationForm = false;
  sortDesc = false;
  appointment: Appointment | null = null;
  account: Account | null = null;
  mentee: ExternalUser | null = null;
  mentor: InternalUser | null = null;
  isClassActive = false;
  mentoringPrograms: Record<string, SmartCode> = {};
  mentoringSystems: Record<string, SmartCode> = {};
  userTypes: Record<string, SmartCode> = {};
  concerns: Record<string, SmartCode> = {};
  accountConcerns: Concerns[] = [];
  accountMentoringPrograms: MentoringProgram[] = [];
  accountMentoringSystems: MentoringSystem[] = [];
  timeZones: ReferenceItem[] = [];
  subscriptions: Subs[] = [];

  get AccountStatus() {
    return AccountStatus;
  }

  get AppointmentDetailViews() {
    return AppointmentDetailViews;
  }

  get menteeEmailSet() {
    return (this.mentee?.emailAddress ?? null) !== null;
  }

  get menteeTimeZoneSet() {
    return (this.mentee?.timeZone ?? null) !== null;
  }

  get accountStatusPill() {
    if (!this.account)
      return '';

    return AccountStatusLookup[this.account.accountStatus];
  }

  get isDangerStatus() {
    switch (this.account?.accountStatus) {
      case AccountStatus.ConsultDecline:
      case AccountStatus.Suspended:
      case AccountStatus.ConsultationCanceled:
        return true;
    }

    return false;
  }

  get isWarningStatus() {
    switch (this.account?.accountStatus) {
      case AccountStatus.PaymentLate:
      case AccountStatus.Cancelled:
      case AccountStatus.TrialEnded:
      case AccountStatus.ResumePending:
      case AccountStatus.PendingCancellation:
        return true;
    }

    return false;
  }

  notesForm = this.formBuilder.group({
    notes: [null, [Validators.required]]
  });

  constructor(
    private appointmentService: AppointmentService,
    private mentorService: MentorService,
    private accountService: AccountService,
    private loaderService: LoaderService,
    private historyService: HistoryService,
    private externalUserService: ExternalUserService,
    private modalService: ModalService,
    private referenceDataService: ReferenceDataService,
    private smartTypesService: SmartTypesService,
    private formBuilder: FormBuilder) {

  }

  ngOnInit(): void {
    if (this.appointmentId) {
      this.appointmentService.getAppointment(this.appointmentId, true);
    }

    if (this.tenant) {
      this.subscriptions.push(this.accountService.getAccount(this.tenant, true).subscribe(account => {
        this.account = account;
        this.mentee = getMentee(account);
      }));

      this.historyService.getHistoryItemsByAccount(this.tenant);
      this.accountService.getAccountConcerns(this.tenant);
      this.accountService.getAccountMentoringPrograms(this.tenant);
      this.accountService.getAccountMentoringSystems(this.tenant);

      this.subscriptions.push(this.accountService.getMentor(this.tenant).subscribe(mentor => {
        this.mentor = mentor;
      }));
    }

    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
    this.subscriptions.push(this.appointmentService.Appointment$.subscribe((appointment) => {
      this.appointment = appointment;
    }));
    this.subscriptions.push(this.accountService.Concerns$.subscribe(concerns => {
      this.accountConcerns = concerns ?? [];
    }));
    this.subscriptions.push(this.accountService.MentoringPrograms$.subscribe(mentoringPrograms => {
      this.accountMentoringPrograms = mentoringPrograms ?? [];
    }));
    this.subscriptions.push(this.accountService.MentoringSystems$.subscribe(mentoringSystems => {
      this.accountMentoringSystems = mentoringSystems ?? [];
    }));

    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringProgram).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.mentoringPrograms[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringSystem).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.mentoringSystems[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Concerns).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.concerns[smartCode.id] = smartCode;
      });
    }));
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType).subscribe(smartCodes => {
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
  }

  ngAfterContentChecked(): void {
    this.modalService.initialize(ModalConstant.DECLINE_MENTEE_MODAL);
    this.modalService.initialize(ModalConstant.EXTERNAL_USER_MODAL);
    this.modalService.initialize(ModalConstant.MENTOR_SELECTION_MODAL);
    this.modalService.initialize(ModalConstant.MENTOR_SELECTION_MODAL);
  }

  getTimeZoneById = getTimeZoneById;

  getUserType(user: ExternalUser | null) {
    if (user == null) {
      return '';
    }

    if (this.userTypes[user.userTypeId].code === 'O') {
      return user.otherUserType;
    } else {
      return this.userTypes[user.userTypeId].label;
    }
  }

  openDeclineMenteeModal() {
    this.modalService.show(ModalConstant.DECLINE_MENTEE_MODAL);
  }

  assignMentor() {
    this.MentorSelectionModalComponent.loadMentors();
  }

  onMentorSelected(mentor: InternalUser) {
    if (this.account?.id == null) return;

    const request: AssignMentorToAccountRequest = {
      userId: mentor.id ?? null,
      accountId: this.account.id
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.mentorService.assignMentorToAccount(request, loaderIdentifier)
      .subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        if (this.tenant == null) return;

        this.accountService.getAccount(this.tenant, true);
        this.historyService.getHistoryItemsByAccount(this.tenant);
      }));
  }

  onMenteeDecline(notes: string) {
    if (this.account?.id) {

      const request: DeclineAccountRequest = {
        notes: notes,
        accountId: this.account.id
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.accountService.declineAccount(request, loaderIdentifier)
        .subscribe(() => {
          this.loaderService.hide(loaderIdentifier);

          if (this.tenant == null) return;
          this.accountService.getAccount(this.tenant, true);
          this.historyService.getHistoryItemsByAccount(this.tenant);
        }));
    }
  }

  editUser(user: ExternalUser | null) {
    this.ExternalUserModalComponent.editUser(user, this.account?.id);
  }

  onAddUser(user: ExternalUser) {
    if (this.account?.id == null) return;

    const request: AddSupportUserToAccountRequest = {
      user: user
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.externalUserService.addSupportUserToAccount(request, this.account.id, loaderIdentifier)
      .subscribe(() => {
        this.loaderService.hide(loaderIdentifier);

        if (this.tenant == null) return;
        this.accountService.getAccount(this.tenant, true);
      }));
  }

  onUpdateUser(user: ExternalUser) {
    const request: EditExternalUserRequest = {
      user: user
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.externalUserService.editExternalUser(request, loaderIdentifier)
      .subscribe(() => {
        this.loaderService.hide(loaderIdentifier);

        if (this.tenant == null) return;
        this.accountService.getAccount(this.tenant, true);
      }));
  }

  navigateToView(view: AppointmentDetailViews) {
    this.changeCurrentView.emit(view);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
