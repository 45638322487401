<form [formGroup]="notesForm" (ngSubmit)="saveNotes()">
    <div class="card mb-2 mya-notes-editor">
        <div class="card-body">

            <div class="row">

                <div class="col-12">
                    <div class="card-title mb-0">
                        <h4 class="mb-0">Mentee Notes</h4>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <angular-editor formControlName="notes" [config]="editorConfig"></angular-editor>
                </div>

                <div class="row m-0">
                    <div class="col-md-6 mt-3" *ngFor="let file of selectedFiles">
                        <div class="card rounded-3 card-hover mb-2 bg-secondary">
                            <div class="card-body p-3 cursor-pointer">

                                <div class="d-flex align-items-center">
                                    <span class="flex-shrink-0">
                                        <i class="ri-file-text-fill text-primary file-icon"></i>
                                    </span>
                                    <div class="ms-4 flex-grow-1 w-50">
                                        <div>
                                            <h4 class="card-title text-dark mb-0 single-line">
                                                {{file.name}}</h4>
                                        </div>
                                    </div>
                                    <div>
                                        <button type="button" class="btn-close" data-bs-dismiss="alert"
                                            aria-label="Close"
                                            (click)="removeFile(file, selectedFiles, fileInputRef)"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-8 mt-3 mb-2">
                </div>

                <div class="col-4 mt-5">

                    <div class="d-flex flex-row-reverse action-buttons">
                        <button class="btn waves-effect waves-light btn-success m-1">
                            Save Notes
                        </button>

                        <input type="file" #fileInput class="d-none" (change)="handleFileInput($event, selectedFiles)"
                            multiple>
                        <button type="button" class="btn waves-effect waves-light btn-primary m-1"
                            (click)="fileInput.click()">
                            Attach Files
                        </button>
                    </div>

                </div>
            </div>

        </div>
    </div>
</form>