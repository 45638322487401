import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { InternalUserService } from '../../services/internal-user.service';
import { InternalUser, InternalUserClaimTypes } from '@mya/models';
import { AuthenticationService } from '../../services/authentication.service';
import { JwtService } from '../../services/jwt.service';
import { ReferenceDataService } from '../../services/reference-data.service';
import { NavigationEnd, Router } from '@angular/router';
import { initializeDarkTheme } from '@mya/xtreme-template';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  loginDisplay = false;
  overriddenDate: Date | null = null;
  userId: string | undefined;
  consultant: InternalUser | null = null;
  url: string | null = null;
  subscriptions: Subs[] = [];

  get name() {
    return this.consultant ? `${this.consultant.firstName} ${this.consultant.lastName}` : '';
  }

  get email() {
    return this.consultant?.emailAddress ?? '';
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  get isDateOverridden() {
    return this.overriddenDate != null;
  }

  constructor(private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private internalUserService: InternalUserService,
    private jwtService: JwtService,
    private authenticationService: AuthenticationService,
    private referenceDataService: ReferenceDataService,
    dateTimeService: DateTimeService,
    public router: Router) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.subscriptions.push(router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.url = value.url;
      }
    }));
  }

  ngAfterViewInit(): void {
    initializeDarkTheme();
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Consultant$.subscribe(user => this.consultant = user));
    this.subscriptions.push(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      }));
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.msalService.instance.setActiveAccount(this.msalService.instance.getAllAccounts()[0]);
      this.subscriptions.push(this.authenticationService.getToken().subscribe(token => {
        const decodedToken: any = this.jwtService.DecodeToken(token);
        const userId = decodedToken[InternalUserClaimTypes.UserId] as string;
        this.internalUserService.getConsultant(userId);
        this.referenceDataService.fetchReferenceData();
      }));
    }
  }

  logout() {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
