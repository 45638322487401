import { Component, Input } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';
import { ModalService } from '../../services/modal.service';
import { BookingService } from '@mya/booking-shared';
import { Subscription } from '@mya/models';

@Component({
  selector: 'mya-edit-appointment-modal',
  templateUrl: './edit-appointment-modal.component.html',
  styleUrls: ['./edit-appointment-modal.component.scss'],
})
export class EditAppointmentModalComponent {
  @Input() selectedBookingAppointmentId: string | null = null;
  @Input() meetingUrl: string | null = null;
  elementId = ModalConstant.EDIT_APPOINTMENT_MODAL;

  constructor(private modalService: ModalService, private bookingService: BookingService) { }

  openJoinAppointmentModal() {
    if (this.meetingUrl)
      window.open(this.meetingUrl, '_blank');
  }
  
  openCancelAppointmentModal() {
    this.modalService.hide(ModalConstant.EDIT_APPOINTMENT_MODAL);
    this.modalService.show(ModalConstant.CANCEL_APPOINTMENT_MODAL);
  }

  openRescheduleAppointmentModal() {
    this.bookingService.GetAppointmentServiceId(this.selectedBookingAppointmentId as string);
    this.modalService.hide(ModalConstant.EDIT_APPOINTMENT_MODAL);
    this.modalService.show(ModalConstant.RESCHEDULE_APPOINTMENT_MODAL);
  }
}
