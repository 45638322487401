import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountStatusLookup, getDataSourceLoaderParams } from '@mya/models';
import { AppointmentService } from '../../services/appointment.service';
import { InternalUserService } from '../../services/internal-user.service';
import { lastValueFrom } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { DatePipe } from '@angular/common';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit, OnDestroy {
  dataSource: any = {};
  applyFilterTypes: any;
  currentFilter: any;
  showFilterRow: boolean;
  showHeaderFilter: boolean;
  subscriptions: Subs[] = [];

  constructor(private router: Router,
    private datePipe: DatePipe,
    private appointmentService: AppointmentService,
    private internalUserService: InternalUserService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
    this.showFilterRow = true;
    this.showHeaderFilter = true;
  }

  get AccountStatusLookup() {
    return AccountStatusLookup;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.internalUserService.Consultant$.subscribe(user => {
      const appointmentService = this.appointmentService;
      if (user?.id) {
        this.dataSource = new CustomStore({
          key: 'id',
          load(loadOptions: any) {
            return lastValueFrom(appointmentService.getConsultationAppointments(getDataSourceLoaderParams(loadOptions)))
              .then((result: any) => ({
                data: result.appointments.data,
                totalCount: result.appointments.totalCount,
                summary: result.appointments.summary,
                groupCount: result.appointments.groupCount,
              }))
              .catch((error: any) => { throw error; });
          },
        });
      }
    }));
  }

  rowSelected(event: any) {
    if (event.selectedRowKeys.length > 0) {
      this.router.navigate([`/appointment-detail/${event.selectedRowsData[0].id}/${event.selectedRowsData[0].accountId}`]);
    }
  }

  fetchAppointmentDate = (data: any) => {
    return this.datePipe.transform(this.getDateWithOffset(new Date(data.startTime)), 'shortDate');
  }

  fetchAppointmentTime = (data: any) => {
    return this.datePipe.transform(this.getDateWithOffset(new Date(data.startTime)), 'shortTime');
  }

  getDateWithOffset(date: Date | null) {
    if (date == null)
      return null;

    const originalDate = new Date(date);
    return date == null ? null : new Date(originalDate.getTime() - (originalDate.getTimezoneOffset() * 60000));
  }

  joinMeeting(e: any) {
    window.open(e.row.data.meetingUrl, '_blank');
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
