import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { AssignMentorToAccountRequest, GetAllMentorsResult, InternalUser } from '@mya/models';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MentorService implements OnDestroy{
  baseApiUrl = '';
  subscriptions: Subs[] = [];

  constructor(private http: HttpClient, private loaderService: LoaderService,
    @Inject(APP_CONFIG) appConfig: any) {
    this.baseApiUrl = `${appConfig.apiUrls.mentor}api/mentor`
  }

  private readonly mentors = new BehaviorSubject<InternalUser[]>([]);
  public readonly Mentors$: Observable<InternalUser[]> = this.mentors;
  private readonly menteeCount = new BehaviorSubject<Record<string, number>>({});
  public readonly MenteeCount$: Observable<Record<string, number>> = this.menteeCount;

  getAllMentors(): void {
    this.subscriptions.push(this.http.get<GetAllMentorsResult>(this.baseApiUrl + `/get-all-mentors`)
      .pipe(catchError((error: HttpErrorResponse) => {
        throw error;
      }))
      .subscribe(data => {
        this.mentors.next(data.internalUsers);
        this.menteeCount.next(data.menteeCount);
      }));
  }

  assignMentorToAccount(request: AssignMentorToAccountRequest, loaderIdentifier: string) : Observable<any> {
    this.loaderService.show(loaderIdentifier);
    return this.http.post(this.baseApiUrl + `/assign-mentor-to-account`, request)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
