<div class="h-100 position-relative">
    <div @disableInitalTrigger class="position-absolute w-100" [ngClass]="{'h-100': currentView === AppointmentDetailViews.Main}">
        <div @mainTrigger *ngIf="currentView === AppointmentDetailViews.Main" class="h-100">
            <mya-appointment-detail [tenant]="tenant" [appointmentId]="appointmentId" (changeCurrentView)="currentViewedChanged($event)"></mya-appointment-detail>
        </div>
    </div>
    <div @disableInitalTrigger class="position-absolute w-100" [ngClass]="{'h-100': currentView === AppointmentDetailViews.ViewAppointments}">
        <div @childTrigger *ngIf="currentView === AppointmentDetailViews.ViewAppointments" class="h-100">
            <mya-mentee-appointments [accountId]="tenant" [appointmentId]="appointmentId" (changeCurrentView)="currentViewedChanged($event)"></mya-mentee-appointments>
        </div>
    </div>
</div>