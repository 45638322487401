import { NgModule } from '@angular/core';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { AppointmentsComponent } from '../components/appointments/appointments.component';
import { AppointmentDetailComponent } from '../components/appointment-detail/appointment-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MentorSelectionModalComponent } from '../modals/mentor-selection-modal/mentor-selection-modal.component';
import { DeclineMenteeModalComponent } from '../modals/decline-mentee-modal/decline-mentee-modal.component';
import { ExternalUserModalComponent } from '../modals/external-user-modal/external-user-modal.component';
import { IMaskModule } from 'angular-imask';
import { CommonModule } from '@angular/common';
import { HomeComponent } from '../components/home/home.component';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { ErrorForbiddenComponent } from '../components/errors/error-forbidden/error-forbidden.component';
import { NotesComponent } from '../components/notes/notes.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NotesViewComponent } from '../components/notes-view/notes-view.component';
import { NoteCardComponent } from '../components/note-card/note-card.component';
import { NotesEditorComponent } from '../components/notes-editor/notes-editor.component';
import { DxDataGridModule } from 'devextreme-angular';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { DateCheckerComponent } from '../components/date-checker/date-checker.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { AppointmentDetailBaseComponent } from '../components/appointment-detail-base/appointment-detail-base.component';
import { MenteeAppointmentsComponent } from '../components/mentee-appointments/mentee-appointments.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CancelAppointmentModalComponent } from '../modals/cancel-appointment-modal/cancel-appointment-modal.component';
import { EditAppointmentModalComponent } from '../modals/edit-appointment-modal/edit-appointment-modal.component';
import { RescheduleAppointmentModalComponent } from '../modals/reschedule-appointment-modal/reschedule-appointment-modal.component';
import { BookingSharedModule } from '@mya/booking-shared';
import { JoinAppointmentModalComponent } from '../modals/join-appointment-modal/join-appointment-modal.component';

@NgModule({
  declarations: [
    HomeComponent,
    DashboardComponent,
    DateCheckerComponent,
    MainLayoutComponent,
    ErrorLayoutComponent,
    AppointmentsComponent,
    AppointmentDetailComponent,
    MentorSelectionModalComponent,
    MenteeAppointmentsComponent,
    AppointmentDetailBaseComponent,
    JoinAppointmentModalComponent,
    DeclineMenteeModalComponent,
    ExternalUserModalComponent,
    NotesViewComponent,
    NotesComponent,
    NoteCardComponent,
    NotesEditorComponent,
    CancelAppointmentModalComponent,
    EditAppointmentModalComponent,
    RescheduleAppointmentModalComponent,
    ErrorForbiddenComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    ReactiveFormsModule,
    FullCalendarModule,
    BrowserAnimationsModule,
    BookingSharedModule,
    FormsModule,
    IMaskModule,
    AngularEditorModule,
    DxDataGridModule,
    NgbPopoverModule
  ]
})
export class LayoutModule { }
