import { Component, Input, OnDestroy } from '@angular/core';
import { ModalConstant } from '../../common/constants/modal.constant';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { RescheduleAppointmentRequest } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { AppointmentService } from '../../services/appointment.service';
import { LoaderService } from '../../services/loader.service';
import { ModalService } from '../../services/modal.service';
import { DateTimeService } from '../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
declare const toastr: any;

@Component({
  selector: 'mya-reschedule-appointment-modal',
  templateUrl: './reschedule-appointment-modal.component.html',
  styleUrls: ['./reschedule-appointment-modal.component.scss'],
})
export class RescheduleAppointmentModalComponent implements OnDestroy {
  @Input() selectedAppointmentId: string | null = null;
  @Input() serviceId: string | null = null;
  @Input() bookingStaffId: string | null = null;
  subscriptions: Subs[] = [];
  overriddenDate: Date | null = null;
  elementId = ModalConstant.RESCHEDULE_APPOINTMENT_MODAL;
  appointmentSlot: BookingAppointmentSlot | null = null;

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(private appointmentService: AppointmentService,
    private loaderService: LoaderService,
    private modalService: ModalService,
    dateTimeService: DateTimeService) {
      this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
  }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.appointmentSlot = date;
  }

  rescheduleAppointment() {
    if (this.selectedAppointmentId && this.serviceId && this.appointmentSlot) {
      const request: RescheduleAppointmentRequest = {
        appointmentId: this.selectedAppointmentId,
        serviceId: this.serviceId,
        startTime: this.appointmentSlot.date
      };

      const loaderIdentifier = uuid();
      this.subscriptions.push(this.appointmentService.rescheduleAppointment(request, loaderIdentifier).subscribe(() => {
        this.loaderService.hide(loaderIdentifier);
        toastr.success("Appointment rescheduled");
        this.modalService.hide(ModalConstant.RESCHEDULE_APPOINTMENT_MODAL);
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
