<div class="modal fade mya-mentor-selection-modal" [id]="elementId" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <form [formGroup]="mentorsForm" (ngSubmit)="onSubmit()">
                <div class="modal-header modal-colored-header bg-primary text-white">
                    <h4 class="modal-title">
                        Assign Mentor
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3 overflow-y-auto mentors-list">
                        <ul class="list-group">
                            <li class="list-group-item cursor-pointer"
                                [ngClass]="{ 'active': mentorsForm.controls.mentor.value === mentor.id}"
                                *ngFor="let mentor of mentors" (click)="assignMentor(mentor)">
                                <div class="d-flex">
                                    <i class="fas fa-user-circle fa-lg me-2"></i>
                                    {{mentor.firstName}} {{mentor.lastName}}
                                    <ng-container *ngFor="let role of mentor.roleAssignments">
                                        <span class="badge bg-info text-dark ms-2"
                                            *ngIf="role.internalRole">{{role.internalRole.description}}</span>
                                    </ng-container>
                                    <span class="flex-grow-1 text-end">Active Mentees: {{menteeCount[mentor.id] ?? 0}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                        Close
                    </button>
                    <button type="submit" class="btn btn-light-primary text-primary font-medium"
                        [disabled]="mentorsForm.invalid">
                        Save changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>