import { Component } from '@angular/core';

@Component({
  selector: 'mya-error-layout',
  templateUrl: './error-layout.component.html',
  styleUrls: ['./error-layout.component.scss'],
})
export class ErrorLayoutComponent {

}
