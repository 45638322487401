import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AppointmentsComponent } from '../components/appointments/appointments.component';
import { HomeComponent } from '../components/home/home.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { ErrorLayoutComponent } from './error-layout/error-layout.component';
import { ErrorForbiddenComponent } from '../components/errors/error-forbidden/error-forbidden.component';
import { RoleGuard } from '../guards/role/role.guard';
import { DateCheckerComponent } from '../components/date-checker/date-checker.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AppointmentDetailBaseComponent } from '../components/appointment-detail-base/appointment-detail-base.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'date-checker', component: DateCheckerComponent },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'home', component:  HomeComponent },
      { path: 'dashboard', component:  DashboardComponent, canActivate: [MsalGuard, RoleGuard] },
      { path: 'appointments', component:  AppointmentsComponent, canActivate: [MsalGuard, RoleGuard] },
      { path: 'appointment-detail/:id/:tenant', component:  AppointmentDetailBaseComponent, canActivate: [MsalGuard, RoleGuard] },
    ]
  },
  {
    path: '',
    component: ErrorLayoutComponent,
    children: [
      { path: 'forbidden', component:  ErrorForbiddenComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
