// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  auth: {
    clientId: '68d7459b-d96d-4c38-a43d-e4275e6de538',
    authority: 'https://login.microsoftonline.com/7fd64d18-fc24-496b-adab-884f35ad54cb',
    redirectUri: 'https://consult.mya.appech.ngrok.io/consult',
    postLogoutRedirectUri: "https://consult.mya.appech.ngrok.io/consult",
    navigateToLoginRequestUrl: false
  },
  graphAPi: "https://graph.microsoft.com/v1.0/me",
  apiUrls: {
    account: "https://api.internal.account.mya.appech.ngrok.io/",
    appointment: "https://api.internal.appointment.mya.appech.ngrok.io/",
    authentication: "https://api.internal.auth.mya.appech.ngrok.io/",
    externalUser: "https://api.internal.external-user.mya.appech.ngrok.io/",
    history: "https://api.internal.history.mya.appech.ngrok.io/",
    internalUser: "https://api.internal.internal-user.mya.appech.ngrok.io/",
    mentor: "https://api.internal.mentor.mya.appech.ngrok.io/",
    smartType: "https://api.internal.smart-type.mya.appech.ngrok.io/",
    referenceData: "https://api.internal.reference-data.mya.appech.ngrok.io/",
    booking:"https://api.internal.booking.mya.appech.ngrok.io/",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
