import { Component, EventEmitter, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { ModalConstant } from '../../common/constants/modal.constant';

@Component({
  selector: 'mya-decline-mentee-modal',
  templateUrl: './decline-mentee-modal.component.html',
  styleUrls: ['./decline-mentee-modal.component.scss'],
})
export class DeclineMenteeModalComponent {
  @Output() menteeDecline = new EventEmitter<string>();
  elementId = ModalConstant.DECLINE_MENTEE_MODAL;

  notesForm = this.formBuilder.group({
    notes: [null, [Validators.required]]
  });

  constructor(private modalService: ModalService,
    private formBuilder: FormBuilder) { }

  onSubmit() {
    if (this.notesForm.controls.notes.value) {
      this.menteeDecline.emit(this.notesForm.controls.notes.value);
      this.notesForm.controls.notes.setValue(null);
      this.modalService.hide(ModalConstant.DECLINE_MENTEE_MODAL);
    }
  }
}
